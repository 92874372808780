import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type SelectedTab = 'social' | 'register' | 'login';

@Component({
  selector: 'app-auth-popup',
  templateUrl: './auth-popup.component.html',
  styleUrls: ['./auth-popup.component.scss'],
})
export class AuthPopupComponent implements OnInit {
  selectedTab: SelectedTab = 'social';

  constructor(public dialogRef: MatDialogRef<AuthPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: { login: boolean }) { }

  ngOnInit(): void {
    if(this.data && this.data.login) {
      this.selectedTab = 'login'
    };
  }

  changeSelectedTab() {
    this.selectedTab = this.selectedTab === 'social' ? 'login' : 'social'; 
  }

}
