import { Component, EventEmitter, inject, Output } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthStore } from "@app/core/auth";
import { AlertService } from "@app/shared/services/alert.service";
import { filter, take } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();
  @Output() goToRegistrationDialog: EventEmitter<void> = new EventEmitter();

  #auth = inject(AuthStore);
  readonly isSubmitting = toSignal(this.#auth.isLoading$);
  readonly form = this.formBuilder.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
    language: ["en", Validators.required],
  });

  languages = [
    {
      name: "English",
      value: "en",
    },
    {
      name: "French",
      value: "fr",
    },
    {
      name: "Spanish",
      value: "es",
    },
  ];

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    const v = this.form.getRawValue();
    this.#auth.localLogin(v.username!, v.password!, v.language!);
    this.#auth.hasSubmitted$.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.closeDialog.emit()
    })
  }

  goToRegistration() {
    this.goToRegistrationDialog.emit();
  }

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
  ) {
  }
}
