<div>
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="w-100 d-flex f-column gap-12">
    <mat-form-field appearance="outline" class="full-width roam-field">
      <input
        matInput
        formControlName="username"
        placeholder="User name"
        autoComplete="off" />
    </mat-form-field>
    <div
      *ngIf="isSubmitting() && f['username'].errors"
      class="details-description red-text">
      Username is required
    </div>
    <mat-form-field appearance="outline" class="full-width roam-field">
      <input
        matInput
        formControlName="password"
        placeholder="Password"
        type="password" />
    </mat-form-field>
    <div
      *ngIf="isSubmitting() && f['password'].errors"
      class="details-description red-text">
      Password is required
    </div>
    <mat-form-field appearance="outline" class="full-width roam-field">
      <mat-select
        formControlName="language"
        class="roam-menu"
        placeholder="Languages">
        <mat-option *ngFor="let language of languages" [value]="language.value">
          {{ language.name }}
        </mat-option>
      </mat-select>
      <button matSuffix mat-icon-button type="button">
        <mat-icon>expand_more</mat-icon>
      </button>
    </mat-form-field>
    <button
      mat-button
      class="view-more"
      [disabled]="form.invalid || isSubmitting()">
      <span [style.visibility]="!isSubmitting() ? 'visible' : 'hidden'"
        >Login</span
      >
      <span
        ><app-loader *ngIf="isSubmitting() && form.valid"></app-loader
      ></span>
    </button>
  </form>
</div>
