import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from '@app/shared/services/alert.service';
import { LogService } from '@app/shared/services/log.service'
import { IUserLogin } from '@app/shared/interfaces'
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { ToastrService } from "ngx-toastr";
import { UserService } from '@app/shared/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Output() goToLoginDialog: EventEmitter<void> = new EventEmitter();

  form!: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private mixpanelService: LogService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.userService.registerUser(this.form.value)
      .pipe(first())
      .subscribe({
        next: (responseData: any) => {
          this.registrationSucceed(responseData);
          this.loading = false;
        },
        error: error => {
          this.registrationFailed(error);
        }
      });
  }

  goToLogin() {
    this.goToLoginDialog.emit();
  }

  registrationSucceed (responseData: IUserLogin) {
    this.mixpanelService.identify(responseData.user!.id);

    this.alertService.success('Registration successful', { keepAfterRouteChange: true });
    this.goToLoginDialog.emit();
  }

  registrationFailed(error: any) {
    this.toastr.error('Registration Failed', '', {...ROAM_TOASTR.center});
    this.loading = false;
  }
}
