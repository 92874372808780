import { LogService } from "@app/shared/services/log.service";

declare var google: any;
// declare var AppleID: { auth: { signIn: () => any } };
// declare var FB: any;

import {
  AfterViewInit,
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IUserLogin } from "@app/shared/interfaces";
import { AlertService } from "@app/shared/services/alert.service";
import { environment } from "@environments/environment";
import { accounts } from "google-one-tap";

@Component({
  selector: "app-social-login",
  templateUrl: "./social-login.component.html",
  styleUrls: ["./social-login.component.scss"],
})
export class SocialLoginComponent implements OnInit, AfterViewInit {
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private ngZone: NgZone,
    private logService: LogService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const gAccounts: accounts = google.accounts;
    gAccounts.id.initialize({
      client_id: environment.googleClientId,
      ux_mode: "popup",
      cancel_on_tap_outside: true,
      callback: ({ credential }) => {
        this.ngZone.run(() => {
          this.googleLoginResult(credential);
        });
      },
    });
    gAccounts.id.renderButton(
      document.getElementById("googleButton") as HTMLElement,
      {
        size: "large",
        width: 330,
        shape: "circle",
      }
    );
  }

  async appleLogin() {
    return;
    // const response = await AppleID.auth.signIn()
    // if (response?.authorization?.id_token) {
    //   this.accountService
    //     .appleLogin(response.authorization.id_token)
    //     .pipe(first())
    //     .subscribe({
    //       next: (responseData: IUserLogin) => {
    //         this.loginSucceed(responseData)
    //       },
    //       error: (error) => {
    //         this.loginFailed(error)
    //       },
    //     })
    // }
  }

  async fbLogin() {
    return;
    // await FB.login(
    //   (response: { status: string; authResponse: { accessToken: any } }) => {
    //     if (response.status === 'connected') {
    //       this.accountService
    //         .fbLogin(response.authResponse.accessToken)
    //         .pipe(first())
    //         .subscribe({
    //           next: (responseData: IUserLogin) => {
    //             this.loginSucceed(responseData)
    //           },
    //           error: (error) => {
    //             this.loginFailed(error)
    //           },
    //         })
    //     }
    //   },
    //   { scope: 'email' }
    // )
  }

  googleLoginResult(token: string) {
    return;
    // this.accountService
    //   .googleLogin(token)
    //   .pipe(first())
    //   .subscribe({
    //     next: (responseData: IUserLogin) => {
    //       this.loginSucceed(responseData)
    //     },
    //     error: (error) => {
    //       this.loginFailed(error)
    //     },
    //   })
  }

  loginSucceed(responseData: IUserLogin) {
    this.logService.identify(responseData.id);

    const returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/overview";
    this.router.navigateByUrl(returnUrl);
    this.closeDialog.emit();
  }

  loginFailed(error: any) {
    this.alertService.error(error);
    this.loading = false;
  }
}
