<div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-100 d-flex f-column gap-12">
        <mat-form-field appearance="outline" class="full-width roam-field">
            <input matInput formControlName="name" placeholder="Name" autoComplete="off" />
        </mat-form-field>
        <div *ngIf="submitted && f['name'].errors" class="details-description red-text">
            Username is required
        </div>
        <mat-form-field appearance="outline" class="full-width roam-field">
            <input matInput formControlName="email" placeholder="Email" type="email" autoComplete="off" />
        </mat-form-field>
        <div *ngIf="submitted && f['email'].errors" class="details-description red-text">
            Email is required
        </div>
        <mat-form-field appearance="outline" class="full-width roam-field">
            <input matInput formControlName="password" placeholder="Password" autoComplete="off" />
        </mat-form-field>
        <div *ngIf="submitted && f['password'].errors" class="details-description red-text">
            Password is required
        </div>
        <button mat-button class="view-more" [disabled]="form.invalid || loading">
          <span  [style.visibility]="!loading ? 'visible' : 'hidden'">Register</span>
          <span><app-loader *ngIf="loading && form.valid"></app-loader></span>
        </button>
    </form>

    <button mat-button (click)="goToLogin()" class="w-100">
        <span class="text-underline">
            Log in
        </span>
    </button>
</div>
