<div class="align-center f-column">
  <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
  <div mat-dialog-title>
    <p class="dialog-title index-mobile">Sign up or log in<br>to continue</p>
  </div>

  <div [ngSwitch]="selectedTab" class="w-100">
    <app-social-login *ngSwitchCase="'social'" (closeDialog)="dialogRef.close()"></app-social-login>
    <app-login *ngSwitchCase="'login'" (closeDialog)="dialogRef.close()" (goToRegistrationDialog)="selectedTab = 'register'"></app-login>
    <app-register *ngSwitchCase="'register'" (goToLoginDialog)="selectedTab = 'login'"></app-register>
  </div>

  <button mat-button class="continue-action" (click)="changeSelectedTab()">
    <span class="roam-title text-underline">{{ selectedTab === 'social' ? 'Continue with Email' : 'Continue with Social Account' }}</span>
  </button>
</div>
