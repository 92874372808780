<div class="align-center f-column social-login">
<!--    <button mat-button (click)="appleLogin()" class="roam-button">-->
<!--        <img src="/assets/svg/home-page/apple.svg" alt="apple icon">-->
<!--        <p class="details-title">Sign In with Apple</p>-->
<!--    </button>-->
    <div id="googleButton"></div>
<!--    <button mat-button color="primary" (click)="fbLogin()" class="roam-button">-->
<!--        <img src="/assets/svg/home-page/facebook.svg" alt="facebook icon">-->
<!--        <p class="details-title">Sign in with Facebook</p>-->
<!--    </button>-->
</div>
